<template>
  <div>
    <b-row class="mb-2">
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1>{{ $t('supplies') }}</h1>
        <b-dropdown
          v-if="isSmallScreen"
          variant="outline-primary"
          right
          :text="$t('add', { type: '' })"
        >
          <b-dropdown-item
            class="mr-1"
            variant="primary"
            @click="$bvModal.show('newMaterial')"
          >
            {{ $t('new', { type: $t('material') }) }}
          </b-dropdown-item>
          <b-dropdown-item
            class="mr-1"
            :to="{
              name: 'addGroupedExpenses',
              params: { projectId: $route.params.projectId },
            }"
            variant="primary"
          >
            {{ $t('add', { type: $t('supply') }) }}
          </b-dropdown-item>
          <b-dropdown-item
            variant="primary"
            @click="downloadGroupedExpenseReport($route.params.projectId)"
          >
            {{ $t('downloadDocument') }}
          </b-dropdown-item>
        </b-dropdown>
        <div v-else>
          <b-button
            class="mr-1"
            variant="primary"
            @click="$bvModal.show('newMaterial')"
          >
            {{ $t('new', { type: $t('material') }) }}
          </b-button>
          <b-button
            class="mr-1"
            :to="{
              name: 'addGroupedExpenses',
              params: { projectId: $route.params.projectId },
            }"
            variant="primary"
          >
            {{ $t('add', { type: $t('supply') }) }}
          </b-button>
          <b-button
            variant="primary"
            @click="downloadGroupedExpenseReport($route.params.projectId)"
          >
            {{ $t('downloadDocument') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="(supply, n) in groupedExpenses"
        :key="n"
        sm="12"
      >
        <supply-card :supply="supply" />
      </b-col>
    </b-row>
    <b-modal
      id="newMaterial"
      @ok="saveExpenses"
      @cancel="newMaterial = {}"
    >
      <b-row>
        <b-col sm="12">
          <b-form-group
            class="mb-0"
            :label="$t('name')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.name"
              min="1"
              class="qty-field"
              autocomplete="off"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            class="mb-0"
            :label="$t('brand')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.brand"
              min="1"
              class="qty-field"
              autocomplete="off"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group
            class="mb-0"
            :label="$t('description')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.description"
              min="1"
              class="qty-field"
              autocomplete="off"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            class="mb-0"
            :label="$t('unit')"
            label-for="input-sm"
          >
            <b-form-select
              v-model="newMaterial.unit_type"
              :options="unitTypeOptions"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            class="mb-0"
            :label="$t('category')"
            label-for="input-sm"
          >
            <b-form-select
              v-model="newMaterial.category"
              text-field="name"
              value-field="id"
              :options="categories"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-0"
            :label="$t('topQuantity')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.top_quantity"
              type="number"
              min="1"
              class="qty-field"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="mb-0"
            :label="$t('topAmount')"
            label-for="input-sm"
          >
            <b-form-input
              v-model="newMaterial.top_amount"
              type="number"
              min="1"
              class="qty-field"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SupplyCard from '@/components/supplies/SupplyCard.vue'

export default {
  components: {
    SupplyCard,
  },
  data() {
    return {
      newMaterial: {},
      materialExpenseType: '',
      unitTypeOptions: [
        {
          value: 'meter',
          text: this.$t('meter'),
        },
        {
          value: 'square_meters',
          text: this.$t('squareMeters'),
        },
        {
          value: 'cubic_meters',
          text: this.$t('cubicMeters'),
        },
        {
          value: 'piece',
          text: this.$t('piece'),
        },
        {
          value: 'kilogram',
          text: this.$t('kilogram'),
        },
        {
          value: 'ton',
          text: this.$t('ton'),
        },
        {
          value: 'bundle',
          text: this.$t('bundle'),
        },
        {
          value: 'box',
          text: this.$t('box'),
        },
        {
          value: 'trip',
          text: this.$t('trip'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters('groupedExpenses', ['groupedExpenses']),
    ...mapGetters('app', ['isSmallScreen']),
    ...mapGetters('categories', ['categories']),
  },

  async mounted() {
    this.fetchCategories()
    await this.fetchGroupedExpenses({ projectId: this.$route.params.projectId })
    const res = await this.fetchExpensesTypeCatalogs(this.$route.params.projectId)
    const expensesTypes = res.data
    this.expenseType = expensesTypes.find(
      expense => expense.name === 'Material',
    ).id
  },
  methods: {
    ...mapActions('groupedExpenses', [
      'fetchGroupedExpenses',
      'downloadGroupedExpenseReport',
      'addGroupedExpenses',
      'fetchExpensesTypeCatalogs',
    ]),
    ...mapActions('categories', ['fetchCategories']),
    saveExpenses() {
      const expense = {
        project_id: this.$route.params.projectId,
        grouped_expense_records: [],
      }
      expense.grouped_expense_records.push(this.newMaterial)
      expense.grouped_expense_records = expense.grouped_expense_records.map(
        item => ({
          expense_type_catalog_id: this.expenseType,
          name: item.name,
          top_quantity: item.top_quantity || 0,
          top_amount: item.top_amount,
          unit_type: item.unit_type,
          brand: item.brand,
          description: item.description,
          sku: item.sku,
        }),
      )
      this.addGroupedExpenses(expense).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('dataSaved'),
            icon: 'MinimizeIcon',
            variant: 'success',
          },
        })
        this.fetchGroupedExpenses({ projectId: this.$route.params.projectId })
        this.$bvModal.hide('newMaterial')
        this.newMaterial = {}
      })
    },
  },
}
</script>

<style></style>
