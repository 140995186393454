<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-row>
      <b-col class="bg-light-primary px-4">
        <span
          v-if="supply.unit_type"
          class="h5"
        >
          {{ $t('unit') }}: {{ $tc(unitsFilter(supply.unit_type)) }}
        </span>
        <span
          v-else
          class="h5"
        >
          {{ $t('noUnit') }}
        </span>
      </b-col>
    </b-row>
    <b-row
      class="d-flex justify-content-between align-items-center my-1 mx-1 rounded-0"
    >
      <b-col
        sm="2"
        class="text-center"
      >
        <span>
          {{ $t('volume') }}
        </span>
      </b-col>
      <!-- <div :style="volumeBar" /> -->
      <b-col
        sm="8"
        class="progress-container rounded-0"
      >
        <b-progress
          height="2.5rem"
          show-value
          :max="supply.purchased_quantity + supply.top_quantity"
          class="rounded-right rounded-left"
        >
          <b-progress-bar
            v-if="+supply.purchased_quantity <= +supply.top_quantity"
            style="background-color:#bedaab; border:2px solid #6f9654; border-right:0; border-radius:10px 0 0 1px"
            class="text-primary font-weight-bolder"
            :value="supply.purchased_quantity"
          />
          <b-progress-bar
            v-if="+supply.purchased_quantity > +supply.top_quantity"
            style="border:2px solid #6f9654; border-right:0; border-radius:10px 0 0 1px"
            class="text-primary font-weight-bolder"
            variant="light-danger"
            :value="supply.purchased_quantity"
          />
          <b-progress-bar
            style="background-color:#fff; border:2px solid #6f9654;"
            class="text-primary font-weight-bolder"
            :value="remanentQuantity"
          />
        </b-progress>
      </b-col>
      <b-col
        sm="2"
        class="text-center"
      >
        <span>
          {{ supply.top_quantity | money }}
        </span>
      </b-col>
    </b-row>
    <b-row
      class="d-flex justify-content-between align-items-center my-1 mx-1 rounded-0"
    >
      <b-col
        sm="2"
        class="text-center"
      >
        <span>
          {{ $t('amount') }}
        </span>
      </b-col>
      <!-- <div :style="volumeBar" /> -->
      <b-col
        sm="8"
        class="progress-container rounded-0"
      >
        <b-progress
          height="2.5rem"
          show-value
          :max="supply.purchased_amount + supply.top_amount"
          class="rounded-right rounded-left"
        >
          <b-progress-bar
            v-if="+supply.purchased_amount <= +supply.top_amount"
            style="background-color:#bedaab; border:2px solid #6f9654; border-right:0; border-radius:10px 0 0 1px"
            class="text-primary font-weight-bolder"
            :value="supply.purchased_amount"
          >
            <span>${{ supply.purchased_amount | money }}</span>
          </b-progress-bar>
          <b-progress-bar
            v-if="+supply.purchased_amount > +supply.top_amount"
            style="border:2px solid #6f9654; border-right:0; border-radius:10px 0 0 1px"
            class="text-primary font-weight-bolder"
            variant="light-danger"
            :value="supply.purchased_amount"
          >
            <span>${{ supply.purchased_amount | money }}</span>
          </b-progress-bar>
          <b-progress-bar
            style="background-color:#fff; border:2px solid #6f9654;"
            class="text-primary font-weight-bolder"
            :value="remanentAmount"
          >
            <span>${{ remanentAmount | money }}</span>
          </b-progress-bar>
        </b-progress>
      </b-col>
      <b-col
        sm="2"
        class="text-center"
      >
        <span> ${{ supply.top_amount | money }} </span>
      </b-col>
    </b-row>
    <b-row
      class="align-items-end h-100"
      v-if="supply.expense_type_catalog.name === 'Material'"
    >
      <b-col class="bg-light-secondary d-flex justify-content-between px-4">
        <span class="h5 text-primary">
          {{ $t('puAverage') }}
        </span>
        <span class="h5 text-primary"> ${{ puAverage | money }} </span>
        <span class="h5 text-primary">
          {{ $t('puGropued') }}
        </span>
        <span class="h5 text-primary"> ${{ puGrouped | money }} </span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    supply: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    remanentQuantity() {
      if (this.supply.top_quantity > 0) {
        const remanent = this.supply.top_quantity - this.supply.purchased_quantity
        if (remanent < 0) return 0
        return remanent
      }
      return 0
    },
    remanentAmount() {
      if (+this.supply.top_amount > 0) {
        const remanent = +this.supply.top_amount - +this.supply.purchased_amount
        if (remanent < 0) return 0
        return remanent
      }
      return 0
    },
    puGrouped() {
      // Handlign inifinity cases
      if (+this.supply.top_quantity === 0) return 0
      return +this.supply.top_amount / +this.supply.top_quantity || 0
    },
    puAverage() {
      // Handlign inifinity cases
      if (+this.supply.purchased_quantity === 0) return 0
      return (
        +this.supply.purchased_amount / +this.supply.purchased_quantity || 0
      )
    },
    ...mapGetters('app', ['unitsFilter']),
  },
}
</script>

<style lang="scss" scoped>
.progress-container {
  ::v-deep .progress-bar {
    border-radius: 0rem !important;
  }
}
</style>
